.requestApplicantContainer {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden font-aeonik;
}

.headerToolBar {
  @apply mb-3;
}

.buttonBase {
  @apply flex gap-[10px];
}

.baseTable {
  @apply flex-1 overflow-y-auto;
}

.filterWrap {
  @apply flex flex-col gap-4 px-[6px];
}

.filterItemCheckbox {
  @apply flex items-center gap-[6px];
}

.labelCheckbox {
  @apply text-14-400-16 text-mineral-green-600 cursor-pointer;
}
