.addCaregiverModal {
  @apply flex justify-center items-center font-aeonik max-h-[100%];
}

.contentModal {
  @apply flex flex-1 flex-col max-h-[100%];
}

.bodyModal {
  @apply flex flex-1 flex-col gap-y-5 overflow-y-scroll;
}

.inputContent {
  @apply grid grid-cols-2 gap-4;
}

.uploadImage {
  @apply flex flex-col mt-4;
}

.uploadLabel {
  @apply block text-12-400-normal text-primary-pewter-300 mb-[10px];
}

.uploadInput {
  @apply hidden;
}

.customUploadButton {
  @apply bg-primary-teal-800/10 h-[64px] inline-flex items-center justify-center rounded-lg cursor-pointer text-center px-4 py-2 border border-dashed border-primary-teal-800 text-primary-teal-800 text-12-400-normal;
}

.footerModal {
  @apply flex justify-end gap-3 mt-10;
}
