.addApplicantComponent {
  @apply flex flex-col max-h-[100%] font-aeonik relative;
}

.contentModal {
  @apply overflow-y-scroll flex-1 gap-5 flex flex-col;
}

.inputContent {
  @apply grid grid-cols-2 gap-5;
}

.footerButton {
  @apply flex justify-end gap-3 mt-[40px];
}
