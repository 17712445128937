.timeClockDetailComponent {
  @apply w-full h-full flex flex-col gap-5;
}

.headerWrap {
  @apply flex flex-col gap-5;
}

.taskName {
  @apply text-24-400-normal text-burnham-950;
}

.line {
  @apply w-full h-[1px] bg-nebula-100;
}

.contentWrap {
  @apply flex flex-col gap-4;
}

.rowDetail {
  @apply flex justify-between w-full;
}

.labelDetail {
  @apply text-14-400-normal text-stack-500 w-[122px] flex-shrink-0;
}

.valueDetail {
  @apply text-primary-teal-950 text-16-400-normal whitespace-normal break-words flex justify-end gap-5 flex-1;
  word-break: break-word;
}
