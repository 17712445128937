.container {
  @apply flex flex-col w-[244px] h-[275px] border rounded-lg border-nebula-100 p-[20px] gap-[12px] relative;
}

.containerContent {
  @apply flex flex-col w-full gap-[12px];
}

.imageCircleWrap {
  @apply flex justify-center items-center;
}

.textGroup {
  @apply flex flex-col gap-1 justify-center items-center text-holly-950 text-12-400-normal;
}

.statusWrap {
  @apply flex flex-row justify-center items-center gap-1;
}

.statusEmpty {
  @apply h-[14px];
}

.statusCircle {
  @apply bg-emerald-500 w-[6px] h-[6px] rounded-full;
}

.statusCircleInactive {
  @apply bg-primary-red-400 w-[6px] h-[6px] rounded-full;
}

.statusText {
  @apply text-emerald-500 text-12-400-normal;
}

.statusTextInactive {
  @apply text-primary-red-400 text-12-400-normal;
}

.nameCaregiver {
  @apply text-holly-950 text-24-400-normal truncate text-center;
}

.contact {
  @apply flex flex-row gap-3;
}

.threeDotWrap {
  @apply absolute top-[10px] right-[10px];
}
