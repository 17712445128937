.container {
  @apply flex-1 flex flex-col h-full w-full overflow-hidden gap-4;
}

.head {
  @apply flex-1 flex justify-end gap-[10px];
}

.content {
  @apply h-full w-full flex flex-col overflow-hidden;
}

.tableWrap {
  @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
  @apply mt-4;
}

.crmTitle {
  @apply flex items-center text-24-400-normal text-holly-950;
}

.crmRingCentralStatus {
  @apply ml-5 text-14-400-normal text-crimson-700 underline cursor-pointer;
  @apply hover:text-crimson-600;
  @apply transition-all duration-300;
}
