.addFilesComponent {
  @apply bg-white w-full h-[300px] rounded-lg p-[30px] overflow-hidden flex flex-col gap-5;
}

.headTitle {
  @apply flex w-full justify-between items-center gap-2 border-b border-nebula-100 pb-4;
}

.title {
  @apply text-16-400-normal text-holly-950;
}

.icon {
  @apply w-[19px] h-[19px] rotate-180;
}

.content {
  @apply overflow-y-scroll h-full;
}
