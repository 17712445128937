.container {
  @apply w-full h-full flex flex-col gap-y-[30px] overflow-hidden;
}

.mainBody {
  @apply flex flex-col w-full h-full gap-y-[20px] overflow-hidden bg-white rounded-[8px] px-[30px] pt-10 pb-[30px];
}

.headGroup {
  @apply w-full flex justify-between border-b border-b-nebula-100 gap-1;
}

.basicInfoGroup {
  @apply flex max-h-[91px] mb-[30px] gap-[10px] items-center;
}

.basicInfo {
  @apply flex gap-[60px] items-center;
}

.nameGroup {
  @apply flex flex-col gap-3;
}

.fullName {
  @apply text-24-400-normal text-holly-950;
}

.valueGroup {
  @apply text-16-400-normal text-pewter-300;
}

.value {
  @apply text-mineral-green-600;
}

.locationGroup {
  @apply flex flex-col gap-2 max-w-[277px] break-words whitespace-normal;
}

.navTabGroup {
  @apply self-end;
}

.tabContent {
  @apply h-full overflow-auto flex flex-col;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}
