.container {
  @apply flex flex-col;
}

// Label
.label {
  @apply mb-[10px] text-12-400-normal text-primary-pewter-300 font-aeonik;
}

.selectBaseLabelRequired{
  @apply text-primary-red-600;
}

// Select
.btnSelect {
  @apply min-w-[140px] flex items-center gap-x-1 p-[10px] bg-white border border-primary-geyser-100 rounded-lg;
}

.btnPlaceholder {
  @apply text-12-400-normal truncate text-primary-sirocco-500 capitalize font-aeonik;
}

.btnText {
  @apply text-12-400-normal bg-primary-teal-800/10 text-primary-teal-800 rounded-lg py-1 pl-2 pr-4 relative;
  @apply whitespace-nowrap overflow-hidden text-clip;
}

.btnTextMore {
  @apply text-12-400-normal  bg-primary-teal-800/10 text-primary-teal-800 rounded-lg py-1 pl-2 pr-4 relative;
  overflow: hidden;
  white-space: nowrap;
}

.iconActive {
  @apply rotate-180;
}

.iconClose {
  @apply absolute top-1/2 -translate-y-1/2 right-1 w-2 h-2;
}

.checkBox {
  @apply mr-2;
}

// Option
.optionList {
  @apply z-[1000] rounded-lg flex flex-col border border-primary-geyser-100 bg-white p-1 w-[var(--button-width)];
  max-height: 400px !important;
  @apply border border-solid border-primary-gray-300 shadow-[0_6px_20px_-2px_rgba(0,0,0,0.2)];
}

.options {
  @apply h-full overflow-y-auto mt-[2px] pr-1;
  &::-webkit-scrollbar {
    @apply w-1 mr-2;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.optionItem {
  @apply w-full p-[8px] text-12-400-normal text-primary-sirocco-500 cursor-pointer rounded-lg mt-[2px] flex justify-between items-center;
}

.optionActive {
  @apply bg-primary-teal-800/10 text-primary-teal-800;
}

.optionHover {
  @apply bg-primary-teal-800/10 text-primary-teal-800;
}

.iconSelected {
  @apply w-[14px] h-[14px];
}

.leftSelect {
  @apply flex items-center;
}

// No data
.optionNoData {
  @apply w-full p-[8px] text-12-400-normal text-primary-sirocco-500 rounded-lg mt-[2px];
}

// Err Message
.errMessage {
  @apply mt-[10px] text-12 px-1 text-primary-red-600;
}

.tooltip {
  @apply flex flex-col gap-1;
}

.tooltipText {
  @apply text-white absolute;
  font-size: 12px !important;
  font-weight: 400 !important;
  z-index: 9999 !important;
}
.iconWrap {
  @apply flex justify-end flex-1 w-[14px];
}
