.payrollTabComponent {
  @apply flex flex-col items-center w-full h-full overflow-y-auto;
}

.content {
  @apply w-full break-words flex flex-col gap-y-4 text-teal-800 text-16-500-normal border border-primary-geyser-100 rounded-lg p-6;
}

.payrollTabComponent::-webkit-scrollbar {
  @apply w-1;
}

.title {
  @apply text-holly-950 font-semibold text-16-400-normal;
}

.titleDollar {
  @apply text-teal-800 text-16-500-normal;
}

.questionContent {
  @apply italic text-16-500-normal;
}

.payRollList {
  @apply grid grid-cols-2 gap-y-3 gap-x-80 pl-5;
}

.itemPayRoll {
  @apply flex flex-row justify-between gap-2;
}

.footerButton {
  @apply flex justify-end gap-3 mt-[40px] ml-auto;
}
