.dailyCareComponent {
    @apply h-full w-full flex flex-col gap-y-4 overflow-hidden;
}

.bodyWrap {
    @apply h-full w-full flex flex-col justify-center overflow-hidden rounded-lg;
}

.statisticTable {
    @apply flex-1 flex justify-center overflow-hidden;
}

.paginationTable {
    @apply mt-4 mb-4 flex justify-center;
}