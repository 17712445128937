.skillTabContainer {
    @apply w-full flex flex-col gap-y-[60px] overflow-hidden;
}

.skillContent {
    @apply mt-[30px] flex flex-wrap gap-x-[25px] gap-y-5;
}

.noDataAvailable {
    @apply flex justify-center w-full;
}

.certificationContent {
    @apply flex flex-wrap gap-[30px] mt-[30px];
}