.container {
    @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden font-aeonik;
}

.headerToolBar {
    @apply mt-[14px] mb-[20px];
}

.textContainer {
 @apply flex justify-center items-center flex-1;
}