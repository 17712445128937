.container {
  @apply w-full h-full flex flex-col p-5 border border-nebula-100 rounded-[8px] overflow-y-auto;
}

.headerChart {
  @apply flex items-center justify-between gap-[10px];
}

.title {
  @apply text-20-400-normal text-holly-950;
}

.donutChartContainer {
  @apply flex flex-col flex-grow overflow-auto my-5;
}

.donutChartContainer::-webkit-scrollbar {
  @apply w-1;
}
.donutChart {
  @apply flex justify-center mt-[53px] mb-[20px];
}

.footerChart {
  @apply flex flex-col items-center gap-4;
}

.legendItem {
  @apply w-[240px] flex items-center justify-between gap-2;
}

.itemWrap {
  @apply flex items-center gap-3;
}

.label {
  @apply text-16-500-normal truncate;
}

.dot {
  @apply w-3 h-3 rounded-full shadow;
}

.blue .dot {
  @apply bg-sky-500;
}

.orange .dot {
  @apply bg-primary-orange-400;
}

.serviceForRating {
  @apply text-holly-950 text-16-250-19;
}

.noData {
  @apply flex w-full h-full justify-center items-center;
}