.addTimeClockComponent {
  @apply flex justify-center items-center font-aeonik max-h-[100%];
}

.contentModal {
  @apply flex flex-1 flex-col max-h-[100%];
}

.bodyModal {
  @apply flex flex-1 flex-col gap-y-5 overflow-y-scroll;
}

.footerModal {
  @apply flex justify-end gap-3 mt-10;
}

.twoCol {
  @apply grid grid-cols-2 gap-5;
}

.threeCol {
  @apply grid grid-cols-3 gap-5;
}

.mapView {
  @apply flex flex-col gap-[10px];
}

.label {
  @apply w-fit text-primary-pewter-300 text-12-400-normal font-aeonik;
}
