.container {
  @apply w-full h-full overflow-y-auto;
}

.container::-webkit-scrollbar {
  @apply hidden;
}

.headerDashboard {
  @apply flex items-center gap-[30px] mb-[30px];
}

.chartDashboard {
  @apply h-[392px] grid grid-cols-7 gap-[30px] mb-[30px];
}

.columnChart {
  @apply col-span-3 bg-white rounded-[4px] min-h-[395px] p-5 flex flex-col;
}

.donutChart {
  @apply col-span-4 bg-white rounded-[4px] min-h-[395px] p-5 flex flex-col overflow-hidden;
}

.tableDashboard {
  @apply bg-white min-h-[485px] rounded-[4px] p-5 flex flex-col;
}

.tableTask {
  @apply bg-white min-h-[485px] rounded-[4px] p-5 flex flex-col mt-[30px];
}

.tableToolbar {
  @apply border-b-[1px] border-b-gray-nurse-100 pb-[17px];
}

.tableStatistic {
  @apply mt-6 h-full flex-1 flex flex-col;
}

.avatarFistLastName {
  @apply flex items-center gap-[6px];
}

.plan {
  @apply text-14-400-normal;
}

.plan.dayCenter {
  @apply text-primary-red-600;
}

.plan.inHome {
  @apply text-finn-900;
}

.plan.transportation {
  @apply text-green-pea-700;
}

.dots {
  @apply flex items-center gap-[3px] cursor-pointer ml-5 w-5 h-5;
}

.dot {
  @apply w-[3px] h-[3px] rounded-full bg-primary-teal-950;
}

.paginationTable {
  @apply mt-4;
}

.tableLink {
  @apply hover:underline hover:text-blue-smoke-500 cursor-pointer;
  @apply transition-all ease-in-out duration-300;
}

.filterWrap {
  @apply flex flex-col gap-4 px-[6px];
}

.filterItemCheckbox {
  @apply flex items-center gap-[6px];
}

.labelCheckbox {
  @apply text-14-400-16 text-mineral-green-600 cursor-pointer;
}

input[type='checkbox'] {
  @apply cursor-pointer;
}

.tableWrap {
  @apply flex-1 overflow-hidden;
}

.headContent {
  @apply flex justify-between items-center mb-[17px];
}

.titleContent {
  @apply text-holly-950 text-24-400-normal break-words;
}

.bodyWrap {
  @apply min-h-[152px] flex-1 ;
}

.noDataMessage {
  @apply flex justify-center items-center h-full;
}