.container {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden font-aeonik;
}

.headerToolBar {
  @apply mb-[20px];
}

.buttonBase {
  @apply flex gap-[10px];
}

.bodyContent {
  @apply flex-1 overflow-y-auto;
}

.bodyWrapCaregiver {
  @apply grid gap-[21px] grid-cols-5 overflow-hidden;
}

.pagination {
  @apply mt-4;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}
