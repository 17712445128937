.skillItemComponent {
    @apply p-[10px] w-fit flex items-center gap-4 rounded-full bg-aqua-squeeze-100;
}

.skillName {
    @apply text-16-400-normal text-primary-teal-950;
}

.btnDelete {
    @apply w-3 h-3;
}