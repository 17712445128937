.originStyle {
  @apply flex justify-center items-center w-fit p-2 border rounded-lg border-gray-300 text-primary-teal-800 text-14-400-normal font-aeonik;
}

.disableButton {
  @apply opacity-70;
}

.iconLeft {
  @apply mr-1;
}

.iconRight {
  @apply ml-1;
}

.originStyle.primary {
  @apply border-none text-white bg-primary-teal-800;
}

.originStyle.onlyText {
  @apply border-none bg-white p-0;
}

.originStyle.white {
  @apply border-[1px] bg-white p-0 rounded-[4px];
}

.originStyle.outline {
  @apply border-none bg-green-pea-700/10;
}

.originStyle.delete {
  @apply border-none text-primary-red-600 bg-red-100;
}
