.crmModalComponent {
    @apply flex flex-col max-h-[100%] font-aeonik gap-[20px];
}
  
.checkboxContainer {
    @apply flex gap-[20px];
}
  
.contentModal {
    @apply overflow-y-auto flex-1 gap-5 flex flex-col pr-2;
  
    &::-webkit-scrollbar {
      @apply w-1;
  }
  
    &::-webkit-scrollbar-thumb {
      @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }
  
    &::-webkit-scrollbar-track {
      @apply rounded-full bg-alto-200;
  }
}
  
.contentModal.hidden-scrollbar {
    scrollbar-width: none;
}
  
.contentModal.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}
  
.twoCol {
    @apply grid grid-cols-2 gap-5;
}
  
.threeCol {
    @apply grid grid-cols-3 gap-5;
}
  
.footerButton {
    @apply flex justify-end gap-3 mt-[40px];
}