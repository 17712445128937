.certificationItemComponent {
    @apply p-4 rounded-lg border border-nebula-100 w-fit;
}

.title {
    @apply text-24-400-normal text-holly-950 mb-3;
}

.courseName {
    @apply text-16-400-normal text-corduroy-500 flex items-center gap-[10px] mb-5;
}

.dot {
    @apply w-[6px] h-[6px] rounded-full bg-amazon-700;
}

.btnCertificate {
    @apply rounded-lg w-full bg-green-pea-700 text-white flex items-center justify-between px-[8px] py-[5px] text-14-400-normal font-aeonik h-[34px];
}

.icon {
    @apply -rotate-90 w-[14px] h-[14px];
}