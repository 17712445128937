.careAlertsShowAllContainer {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden font-aeonik;
}

.headerToolBar {
  @apply mt-[14px] mb-[20px];
}

.buttonBase {
  @apply flex gap-[10px];
}

.bodyContent {
  @apply flex-1 overflow-y-auto;
}

.bodyWrapCareAlert {
  @apply grid gap-x-[31px] gap-y-[20px] grid-cols-3 overflow-hidden;
}

.noDataAvailable {
  @apply flex justify-center items-center h-full flex-1;
}
