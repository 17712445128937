.container {
  @apply w-full h-full flex flex-col bg-white rounded-[4px] px-5 pt-5 pb-6 overflow-hidden;
}

.title{
  @apply text-24-400-normal text-holly-950;
}

.body{
  @apply flex-1 overflow-hidden mt-6;
}

.form {
  @apply flex flex-col max-h-[100%]  font-aeonik overflow-hidden;
}

.addClientFormContent {
    @apply overflow-y-auto gap-5 flex-1 flex flex-col pr-2;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-mineral-green-600 rounded-lg cursor-pointer;
  }

  &::-webkit-scrollbar-track {
    @apply rounded-full bg-alto-200;
  }
}

.addClientFormContent.hidden-scrollbar {
  scrollbar-width: none;
}
  
.addClientFormContent.hidden-scrollbar::-webkit-scrollbar {
  display: none; 
}
  
.twoCol {
  @apply grid grid-cols-2 gap-5;
}
  
.threeCol {
  @apply grid grid-cols-3 gap-5;
}
  
.footerButton {
  @apply flex justify-end gap-3 mt-[40px];
}