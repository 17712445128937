.messageIsMe {
  @apply flex justify-end;
}

.message,
.messageIsMe {
  @apply mb-5;
}

.conversationMessageContent {
  @apply flex flex-col gap-3;
}

.conversationMessageFirstLastName {
  @apply flex;
}

// Message text
.message .textMessageContent {
  @apply text-14-400-normal text-mineral-green-600 px-4 py-5 border border-nebula-100 rounded-tl-[0px];
}

.messageIsMe .textMessageContent {
  @apply text-14-400-normal text-mineral-green-600 border border-nebula-100 bg-green-pea-700/5 rounded-tr-[0px] self-end;
}

.textMessageContent {
  @apply w-fit max-w-[415px] px-4 py-5 rounded-[16px] whitespace-pre-wrap break-words;
}

// Message photo
.message .photoMessage {
  @apply h-40 w-40 object-cover rounded-lg;
}

.messageIsMe .photoMessage {
  @apply h-40 w-40 object-cover rounded-lg self-end;
}

// Message video
.videoMessage {
  @apply h-40 w-[280px] object-cover rounded-lg;
}

.avatarFirstLastName {
  @apply flex gap-3 items-start;
}

.messageIsMe .avatarFirstLastName {
  @apply flex gap-3 items-start justify-end;
}

.firstLastName {
  @apply flex flex-col gap-1;
}

.memberUserName {
  @apply text-14-400-normal text-green-950;
}

.dateTime {
  @apply text-12-400-normal text-mineral-green-600;
}

.conversationMessageTime {
  @apply text-10-500-12 text-mineral-green-600/80 flex items-center;
}

.messageTimeWrap {
  @apply w-full flex justify-end items-center mt-3;
}

.fileImg {
  @apply w-16 h-16 rounded-md object-cover;
}

.message .viewFile {
  @apply h-32 w-64 flex flex-col items-center justify-center border-nebula-100 border-[1px] rounded-md gap-y-2 p-3 bg-gray-200;
}

.messageIsMe .viewFile {
  @apply h-32 w-64 flex flex-col items-center justify-center border-nebula-100 border-[1px] rounded-md gap-y-2 p-3 self-end bg-gray-200;
}

.nameFile {
  @apply text-14-400-normal text-green-950 w-full truncate text-center text-ellipsis;

  direction: rtl;
}

.loadingContent {
  @apply w-10 h-10 inline-block box-border animate-spin rounded-full border-[5px] border-green-pea-700 border-b-transparent border-solid;
}

.loadingContainer {
  @apply h-40 w-40 flex flex-col border-nebula-100 border-[1px] rounded-md items-center justify-center bg-gray-200;
}

.loadingFile {
  @apply h-32 w-64;
}
