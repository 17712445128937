.container {
  @apply w-full h-full flex flex-col rounded-xl py-4 overflow-hidden overflow-y-auto;
  @apply transition-all ease-linear duration-300;
}

.container::-webkit-scrollbar {
  @apply w-1;
}

.container::-webkit-scrollbar-thumb {
  @apply bg-mineral-green-600 rounded-full;
}

.container::-webkit-scrollbar-track {
  @apply bg-alto-200 rounded-full;
}

.containerContent {
  @apply ml-[-30px];
}

.containerDay {
  @apply h-6 text-12-400-normal flex justify-center items-center rounded-[5px] bg-gray-300 text-green-pea-700;
}

.timelineSeparator {
  @apply ml-3;
}

.contentDate {
  @apply text-14-400-24 text-holly-950;
}

.iconContent {
  @apply w-[41px] bg-green-pea-700/30 rounded-3xl h-[41px] my-[10px] flex justify-center items-center;
}

.timelineConnector {
  @apply h-10;
}

.contentNameGroup {
  @apply w-[450px] flex flex-col;
}

.contentName {
  @apply text-green-pea-700 text-16-400-26;
}

.contentNameDeleted {
  @apply text-primary-red-600;
}

.contentCreatedBy,
.contentText {
  @apply text-green-pea-700 text-14-400-16 mt-2;
}

.textLink {
  @apply hover:underline hover:text-blue-smoke-500 cursor-pointer;
  @apply transition-all ease-in-out duration-300;
}

.actions {
  @apply flex gap-5;
}

.details {
  @apply text-14-400-16 cursor-pointer text-green-pea-700 rounded flex justify-end items-end;
}

.containerSpinner {
  @apply h-8 flex items-center justify-center;
}

.noDataAvailable {
  @apply text-14-400-16 text-green-pea-700 items-center flex justify-center w-full h-full;
}
